export const footerCommunity = [
  {
    name: 'LinkedIn',
    path: 'https://www.linkedin.com/in/the-root-app'
  },
  {
    name: 'YouTube',
    path: 'https://www.youtube.com/@TheRootAppCC'
  },
  {
    name: 'Reddit',
    path: 'https://www.reddit.com/r/theroot_ccapp/'
  },
  {
    name: 'Twitter',
    path: 'https://twitter.com/theroot_app'
  }
];
