import React from 'react';
import {Container, Row} from "react-bootstrap";

import {pricingPacks, pricingSubtitle, pricingTitle} from "../_pagesContent/PricingPage";
import PricingPagePackCard from "../components/PricingPagePackCard";

const PricingPage = () => {
  return (
    <>
      <Container className="pricing-page">
        <Container className="container-middle">
          <h1 className="page-title">
            {pricingTitle}
          </h1>
          <div className="page-subtitle">
            {pricingSubtitle}
          </div>

          <Row className="packs__wrapper">
            {pricingPacks.filter(pack => pack.category.toLowerCase() === 'cloud').map((pack, idx) => (
              <PricingPagePackCard {...pack} key={'cloudPacksBlock' + idx}/>
            ))}
          </Row>

          <Row>
            {pricingPacks.filter(pack => pack.category.toLowerCase() === 'host').map((pack, idx) => (
              <PricingPagePackCard {...pack} key={'hostPacksBlock' + idx}/>
            ))}
          </Row>

          <Row>
            {pricingPacks.filter(pack => (pack.category.toLowerCase() !== 'cloud' && pack.category.toLowerCase() !== 'host')).map((pack, idx) => (
              <PricingPagePackCard {...pack} key={'anotherPacksBlock' + idx}/>
            ))}
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default PricingPage;
