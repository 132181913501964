import group1 from "../assets/tmp/group_1.png";
import group2 from "../assets/tmp/group_2.png";
import group3 from "../assets/tmp/group_3.png";


// Home Page content

export const homePageLabel = 'TheRoot C&C';
export const homePageTitle = 'No more intricate technical jargon or time-consuming processes';
export const homePageSubtitle = 'A key advantage of our software is its intuitive interface, letting project managers, IT admins, and security staff assign or adjust roles at least 5x faster than traditional tools. It boosts team collaboration and communication, ensuring clarity on roles and responsibilities with minimal effort.';

export const homePageContent = [
  {
    label: 'Revolutionary Thinking',
    title: 'Revolutionize IT, Elevate Business: TheRoot Unlocks Your Potential',
    description: 'Our cutting-edge product offers an unprecedented, consolidated view of users across all connected systems. Say goodbye to the hassle of navigating multiple platforms to manage user roles—our solution brings everything together in one unified interface, saving you time and effort.',
    content: [
      {
        faIcon: 'tr tr-icon-monitoring',
        name: 'Simplified Role Modification:',
        desc: 'Take the complexity out of user role management. With our streamlined interface, you can quickly modify or delete assigned roles across multiple systems. No more jumping between different platforms—everything you need is at your fingertips.',
        image: group1
      },
      {
        faIcon: 'tr tr-icon-fa_chain-broken',
        name: ' Visual Role Management:',
        desc: 'Easily visualize all user roles with our dynamic, user-friendly interface. Whether you’re managing a small team or an entire organization, our product ensures you have a clear overview of role assignments, helping you maintain control and compliance effortlessly.',
        image: group1
      },
      {
        faIcon: 'tr tr-icon-attach_money',
        name: 'Unified User Insights:',
        desc: 'Gain a comprehensive, real-time view of users across all connected systems. Our product provides an intuitive dashboard that consolidates user data, offering a holistic perspective of user roles and their assignments in external systems.',
        image: group1
      }
    ]
  },
  {
    label: 'Strategic Planning Made Simple',
    title: 'Strategic Alignment with Ease:',
    description: 'Achieve seamless alignment between IT workflows and your business objectives. Our platform empowers teams to focus on long-term goals while streamlining role and access management for sustainable growth.',
    content: [
      {
        faIcon: 'tr tr-icon-stacked_line_chart',
        name: ' Efficiency That Drives Results:',
        desc: 'Simplify complex processes with a user-friendly interface that centralizes role assignments, minimizes repetitive tasks, and enhances collaboration across all departments—accelerating project execution and decision-making.',
        image: group2
      },
      {
        faIcon: 'tr tr-icon-badge',
        name: 'Empowered and Engaged Teams:',
        desc: ' Enable IT staff to focus on high-value strategic work while empowering non-technical employees to handle role assignments independently. The result? Higher morale, greater efficiency, and stronger employee retention.',
        image: group2
      },
      {
        faIcon: 'tr tr-icon-add_shopping_cart',
        name: 'Unlock Your Business Potential:',
        desc: 'TheRootApp C&C revolutionizes role management by empowering your IT team, driving innovation, and aligning IT strategies with your business vision. Simplify operations, reduce costs, and enhance security with a streamlined, intuitive platform. Embrace the transformation that propels your organization into the future of success.',
        image: group2
      }
    ]
  },
  {
    label: 'Extend seamlessly',
    title: 'Seamless Integration with Your Favorite Tools.',
    description: 'Effortlessly connect your preferred tools with TheRootApp, enhancing their capabilities and integrating them into a unified platform. Simplify your workflow, save time, and unlock endless possibilities for streamlined operations.',
    content: [
      {
        faIcon: 'tr tr-icon-terminal',
        name: 'Starting with Jira, Expanding to More:',
        desc: ' Jira is just the beginning. Our integration roadmap includes tools like GitHub, GitLab, Confluence, and more, ensuring your team has access to a comprehensive suite of solutions to elevate productivity and collaboration.',
        image: group3
      },
      {
        faIcon: 'tr tr-icon-account_tree',
        name: 'Simplified Platform for Maximum Efficiency:',
        desc: ' Optimize your workflow, streamline project management, and enhance security with our intuitive, user-focused solution. Empower your team to achieve more, faster, with fewer obstacles.',
        image: group3
      },
      {
        faIcon: 'tr tr-icon-calendar_clock',
        name: 'It is time to Break Free from Technical Overhead:',
        desc: 'Say goodbye to the grind of repetitive IT tasks. Embrace a transformative solution that reduces complexity, drives innovation, and elevates your business to new levels of success.',
        image: group3
      }
    ]
  }
];

export const homePageAddLabel1 = 'Unveiling Beyond';
export const homePageAddTitle1 = 'Explore the Extraordinary.';
export const homePageAddSubtitle1 = 'Discover New Dimensions with Exciting Integrations – Because That\'s Not All, There\'s More to Embrace!';

export const homePageAddLabel2 = 'Take-off faster';
export const homePageAddTitle2 = 'Seamless launch into unparalleled efficiency and productivity';
export const homePageAddSubtitle2 = 'Elevate Your Productivity, Safeguard Your Privacy, and Optimize Your Workflow: TheRootApp is Designed to Propel You Toward Effortless Goal Attainment.';

export const homePageAdditionalContent_grid3 = [
  {
    faIcon: 'tr tr-icon-stars',
    title: 'Personalize Your Experience, Own It for Eternity',
    description: 'Shape Your Experience with TheRootApp. Sculpt Your Workspace for Intuitive, Efficient, and Effortless Operation."'
  },
  {
    faIcon: 'tr tr-icon-school',
    title: 'Grasp Once, Recall Endlessly: Absorb Knowledge, Keep It Forever!',
    description: 'Effortless Takeoff, Swift Mastery: Seamlessly Navigate with Minimal Effort. Intuitive Design, Unforgettable Learning Experience.'
  },
  {
    faIcon: 'tr tr-icon-network_wifi_locked',
    title: 'Secure, by design and continuity',
    description: 'At TheRootApp, prioritizing security is paramount, and the platform is meticulously designed with a focus on safeguarding your information. Regular security updates are implemented to ensure the highest level of protection for your data and privacy.'
  }
];
