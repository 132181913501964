import React, {FC, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";

type TContentItem = {
  faIcon: string,
  name: string,
  desc: string,
  image: string
}

interface IHomePageContentRow {
  label: string,
  title: string,
  description: string,
  content: TContentItem[],
  direction?: string
}

const HomePageContentRow: FC<IHomePageContentRow> = ({ label, title, description, content, direction = 'ltr' }) => {
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIdx((activeIdx + 1) % content.length);
    }, 5000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [activeIdx]);

  const clickHandler = (idx: number) => {
    setActiveIdx(idx);
  };

  return (
    <Row className={`content-row content-row--${direction}`}>
      <Col xs={12} lg={6} className="content-row__col">
        <div className="content-row__col-inner">
          <h2 className="content-row__label">{label}</h2>
          <p className="content-row__title">{title}</p>
          <p className="content-row__description">{description}</p>
          <div className="content-row__content-items-wrapper">
            {content.map((el, idx) => {
              return (
                <div key={'homePageContentRowInner' + idx}
                     className={`content-row__content-item${activeIdx === idx ? ' content-row__content-item--active' : ''}`}
                >
                  <span className={content[idx].faIcon} aria-hidden="true"/>
                  <span><strong>{content[idx].name}</strong> {content[idx].desc}</span>
                </div>
              )
            })}
            <div className="content-row__content-items-indicators">
              {content.map((_, idx) => (
                <div key={'homePageContentRowIndicator' + idx}
                     className={`content-row__content-items-indicator${activeIdx === idx ? ' content-row__content-items-indicator--active' : ''}`}
                     onClick={() => clickHandler(idx)}
                />
              ))}
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6} className="content-row__image-col">
        <div className="content-row__image-wrapper">
          <img src={content[activeIdx].image} alt="" className="content-row__image"/>
        </div>
      </Col>
    </Row>
  );
};

export default HomePageContentRow;
