import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../models";

interface UserState {
  user: IUser | {},
  isLoading: boolean,
  error: string
}

const initialState: UserState = {
  user: {},
  isLoading: false,
  error: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getMeRequest(state) {
      state.isLoading = true;
    },
    getMeSuccess(state, action: PayloadAction<IUser>) {
      state.isLoading = false;
      state.error = '';
      state.user = action.payload;
    },
    getMeFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload
    },
  }
})

export default userSlice.reducer;
