import React, {FC, useMemo} from 'react';
import {Col} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

interface IPricingPagePackCard {
  category?: string,
  title: string,
  description: string,
  price?: string | number | null,
  inviteBtn: string,
  inviteLink: string,
  conditions: string[]
}

const PricingPagePackCard: FC<IPricingPagePackCard> = (props: IPricingPagePackCard) => {
  const navigate = useNavigate();

  const price = useMemo(() => {
    if (isNaN(Number(String(props.price)))) return 'Coming Soon';
    try {
      const value = Math.round(Number(props.price) * 100) / 100;
      return '$ ' + value;
    } catch (e) {
      return 'Coming Soon';
    }
  }, [props.price]);

  const onRedirectHandler = () => {
    navigate(props.inviteLink);
  };

  return (
    // <Col xs={12} lg={6} className="packs__cell">
      <div className="packs__card">
        <h3 className="packs__card-title">{props.title}</h3>
        <p className="packs__card-description">{props.description}</p>
        <p className="packs__card-price">{price}</p>

        <a href="https://cal.com/therootapp" target="_blank" rel="noopener noreferrer" className="btn btn-outline-info">{props.inviteBtn}</a>

        <ul className="packs__card-conditions">
          {props.conditions.map((el, idx) => (
            <li key={'packCondition' + String(props.price) + props.title + idx} className="packs__card-condition-item">
              <i className="fa fa-check" aria-hidden="true"/>
              <span>{el}</span>
            </li>
          ))}
        </ul>
      </div>
    // </Col>
  );
};

export default PricingPagePackCard;
