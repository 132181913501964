import React, {FC} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Navbar, NavItem} from "react-bootstrap";
import nav from "../nav";

const DefaultNavigation: FC = () => {
  const location = useLocation();

  return (
    <nav className="default-navigation">
      <Navbar>
        {nav.map((el, idx) => {
          return (
            <NavItem key={'defaultNavItem' + el.name + idx} className={location.pathname.includes(el.path) ? ' nav-item--current' : ''}>
              <Link to={el.path}>
                {el.name}
              </Link>
            </NavItem>
          )
        })}
      </Navbar>
    </nav>
  );
};

export default DefaultNavigation;
