import React, { FC } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';

const defaultProps: Settings = {
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  dots: true,
  variableWidth: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 10000
};

const Carousel: FC<Settings & { horizontalCentered?: boolean }> = ({ horizontalCentered = false, ...props }) => {
  return (
    <Slider
      {...defaultProps}
      {...props}
    />
  )
}

export default Carousel;
