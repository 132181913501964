import React from 'react';
import {Container} from "react-bootstrap";

const TermsNConditionsPage = () => {
  return (
    <Container className="default-page terms-page">

      <h1 className="page-title terms__page-title">
        Terms and Conditions for TheRootApp
      </h1>
      <p className="terms__date">
        Effective Date: [26.11.2024] <i>update pending</i>
      </p>

      <p className="terms__partition">
        Welcome to TheRoot, where innovation meets precision. These Terms and Conditions (the “Terms”) govern your access to and use of our software-as-a-service (SaaS) platform, available at https://theroot.app (the “Service”). These Terms are legally binding, and by accessing or using the Service, you unequivocally agree to comply with them. If you do not agree, you are expressly forbidden from using the Service.
        This agreement is not subject to negotiation. Proceed only if you are prepared to adhere strictly to these provisions.
      </p>

      <div className="terms__chapter">
        1. Definitions and Scope:
      </div>
      <p className="terms__partition">
        1.1 "TheRoot App" encompasses all services, features, tools, and content available on the platform.
      </p>
      <p className="terms__partition">
        1.2 "User" or "you" means any entity or individual accessing or interacting with the Service.
      </p>
      <p className="terms__partition">
        1.3 "Account" refers to the unique access credentials assigned to each User.
      </p>
      <p className="terms__partition">
        1.4 "Content" includes all data, text, media, software, and other materials, whether uploaded by the User or generated through the Service.
      </p>

      <div className="terms__chapter">
        2. User Accounts - Your Responsibility:
      </div>
      <p className="terms__partition">
        2.1 Registration Requirements: To gain access, you must create an account and provide accurate, up-to-date, and complete information. False or misleading details will result in immediate suspension or termination of access.
      </p>
      <p className="terms__partition">
        2.2 Account Security: You are solely responsible for safeguarding your account credentials. Unauthorized access under your credentials will be treated as your action.
      </p>
      <p className="terms__partition">
        2.3 Eligibility: The Service is intended for users over the age of 18. By using the Service, you confirm that you meet this requirement.
      </p>

      <div className="terms__chapter">
        3. Use of the Service: Strict Compliance Required:
      </div>
      <p className="terms__partition">
        3.1 License Grant: We grant you a limited, non-transferable, revocable license to access and use the Service in strict accordance with these Terms.
      </p>
      <p className="terms__partition">
        3.2 Prohibited Actions: Engaging in the following activities will result in immediate and possibly permanent banishment from the Service:
        <ul>
          <li>Attempting to reverse-engineer, decompile, or extract the source code of the Service.</li>
          <li>Exploiting the Service for illegal or unauthorized activities, including but not limited to fraud, hacking, or dissemination of malware.</li>
          <li>Circumventing or attempting to bypass security protocols or restrictions.</li>
        </ul>
      </p>

      <p className="terms__partition">
        3.3 Content Ownership: While you retain ownership of your content, by uploading it to TheRoot, you grant us an irrevocable, royalty-free, worldwide license to use, store, modify, and reproduce it solely for operating and improving the Service.
      </p>

      <div className="terms__chapter">
        4. Subscriptions and Payment: Non-Negotiable
      </div>
      <p className="terms__partition">
        4.1 Paid Access: Many features require an active subscription. Pricing details are listed at https://theroot.app/pricing.
      </p>
      <p className="terms__partition">
        4.2 Payment Obligations: By subscribing, you authorize us to charge your payment method for recurring fees. Failure to pay on time will result in suspension or termination of access.
      </p>
      <p className="terms__partition">
        4.3 Refund Policy: All sales are final. Refunds will not be issued except in cases of demonstrable error on our part.
      </p>

      <div className="terms__chapter">
        5. Intellectual Property: Respect Our Ownership:
      </div>
      <p className="terms__partition">
        5.1 Service IP:<br/>
        All elements of the Service - including but not limited to trademarks, algorithms, and interfaces - are the exclusive property of TheRoot or its licensors. Unauthorized use will result in legal action.
      </p>
      <p className="terms__partition">
        5.2 User Content: You represent and warrant that you own or have the rights to all content you upload to the Service. Misrepresentation will expose you to liability.
      </p>

      <div className="terms__chapter">
        6. Data Privacy and Security: No Absolute Guarantees
      </div>
      <p className="terms__partition">
        6.1 Privacy Policy: Your use of the Service is subject to our Privacy Policy (https://theroot.app/privacy).
      </p>
      <p className="terms__partition">
        6.2 Data Risks: Although we implement robust security measures, no system is immune to breaches. You use the Service at your own risk.
      </p>

      <div className="terms__chapter">
        7. Termination: Our Right to End This Relationship
      </div>
      <p className="terms__partition">
        7.1 By You: You may terminate your account at any time. However, prepaid fees are non-refundable, and your data may be retained as outlined in our Privacy Policy.
      </p>
      <p className="terms__partition">
        7.2 By Us: We reserve the right to suspend or terminate your account immediately if:
        <ul>
          <li>You breach any part of these Terms.</li>
          <li>You engage in activities deemed harmful, fraudulent, or unlawful.</li>
        </ul>
      </p>
      <p className="terms__partition">
        7.3 Post-Termination Obligations: Following termination, your access to the Service will cease immediately. We
        may retain your data to comply with legal obligations or our internal policies.
      </p>

      <div className="terms__chapter">
        8. Disclaimer of Warranties: You Accept the Risks
      </div>
      <p className="terms__partition">
        THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE.” WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO GUARANTEES REGARDING UPTIME, DATA LOSS, OR ERROR-FREE PERFORMANCE.
      </p>

      <div className="terms__chapter">
        9. Limitation of Liability: Our Liability is Limited to the Maximum Extent Allowed
      </div>
      <p className="terms__partition">
        UNDER NO CIRCUMSTANCES SHALL THE ROOT, ITS AFFILIATES, OR PARTNERS BE LIABLE FOR INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, EVEN IF ADVISED OF SUCH RISKS. OUR TOTAL LIABILITY SHALL NOT EXCEED THE FEES YOU HAVE PAID TO US IN THE PAST THREE (3) MONTHS.
      </p>

      <div className="terms__chapter">
        10. Amendments: Changes Are Inevitable
      </div>
      <p className="terms__partition">
        We reserve the right to update these Terms at any time without prior notice. It is your responsibility to review them regularly. Continued use of the Service constitutes acceptance of revised Terms.
      </p>

      <div className="terms__chapter">
        11. Contact Information
      </div>
      <p className="terms__partition">
        For any inquiries about these Terms, please reach out to us:<br/>
        [<a href="mailto:contact@theroot.app" className="terms__link">contact@theroot.app</a>]
      </p>
      <p className="terms__partition mt-5">
        By accessing TheRoot App, you confirm your understanding and acceptance of these strict and non-negotiable Terms. We mean business.
      </p>
    </Container>
  );
};

export default TermsNConditionsPage