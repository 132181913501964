import React, {FC} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import nav from "../nav";
import {Link} from "react-router-dom";
import {footerCommunity} from "../_pagesContent/footer";
import iconLogo from "../assets/logo_blue.svg";
import GetStarted from "../components/GetStarted";

const DefaultFooter: FC = () => {
  return (
    <footer className="default-footer">
      <div className="join-promo join-promo__box">
        <div className="bg-gradient-wrapper"/>
        <div className="join-promo__content-wrapper">
          <h2 className="join-promo__title">
            Join our Community!
          </h2>
          <p className="join-promo__description">
            Participate in the discussion, present your ideas for features, explore clever solutions, and benefit from the insights of a dynamic community of developers and passionate supporters of TheRoot.
          </p>
          <div className="button-group">
            <GetStarted />
          </div>
        </div>
      </div>

      <Container>
        <Row>
          <Col xs={12} md={5} className="d-flex flex-column justify-content-between">
            <div>
              <div className="default-header__logo-wrapper">
                <Link to="/">
                  <img src={iconLogo} alt="" className="default-header__logo-image"/>
                </Link>
              </div>
              <GetStarted />
            </div>
            <div className="footer-copyright">
              © {new Date(Date.now()).getFullYear()} TheRoot. All rights reserved.
            </div>
          </Col>

          <Col xs={12} md={7}>
            <div className="footer-links">
              <div>
                <h5 className="footer-links__title">Company</h5>
                <ul>
                  {nav.map((el, idx) => (
                    <li key={'footerLink' + idx + el.path}>
                      <Link to={el.path}>{el.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h5 className="footer-links__title">Legal</h5>
                <ul>
                  <li><Link to={'/privacy'}>Privacy Policy</Link></li>
                  <li><Link to={'/terms'}>Terms & Conditions</Link></li>
                  <li><a href="/impressum" rel="noopener noreferrer" target="_blank">Impressum</a></li>
                </ul>
              </div>
              <div>
                <h5 className="footer-links__title">Community</h5>
                <ul>
                  {footerCommunity.map((el, idx) => (
                    <li key={'footerLink' + idx + el.path}>
                      <a href={el.path} target="_blank" rel="noopener noreferrer">{el.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
