import React from 'react';
import {Container} from "react-bootstrap";
import {aboutSocials, aboutSubtitle, aboutTitle} from "../_pagesContent/AboutPage";

const AboutPage = () => {
  return (
    <Container className="about-page">
      <Container className="container-middle">
        <h1 className="page-title">
          {aboutTitle}
        </h1>
        <div className="page-subtitle">
          {aboutSubtitle.map((el, idx) => (
            <p key={'aboutSubtitle' + idx}>{el}</p>
          ))}
        </div>
      </Container>

      <div className="about-socials">
        {aboutSocials.map((el, idx) => (
          <div key={'aboutSocItem' + el.name + idx} className="about-socials__item">
            <a href={el.link} className="about-socials__link" target="_blank" rel="noopener noreferrer">
              <img src={el.icon} alt=""/>
              <span>{el.name}</span>
            </a>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default AboutPage;
