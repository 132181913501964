import { createBrowserRouter } from 'react-router-dom';
import DefaultLayout from "./layouts/DefaultLayout";
import {ErrorPage} from "./pages/ErrorPage";

import HomePage from "./pages/HomePage";
import PricingPage from "./pages/PricingPage";
import WhyBecomeTheRootPage from "./pages/WhyBecomeTheRootPage";
import FaqPage from "./pages/FaqPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsNConditionsPage from "./pages/TermsNConditionsPage";

export const router = createBrowserRouter([
  {
    element: <DefaultLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/pricing', element: <PricingPage /> },
      { path: '/whybecometheroot', element: <WhyBecomeTheRootPage /> },
      { path: '/faq', element: <FaqPage /> },
      { path: '/about', element: <AboutPage /> },
      { path: '/privacy', element: <PrivacyPolicyPage /> },
      { path: '/terms', element: <TermsNConditionsPage /> },
    ],
    errorElement: <ErrorPage />,
  },
  {
    // element: <AuthPage />,
    // path: '/auth',
  },
]);
