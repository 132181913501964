import React, {FC} from 'react';
import {Accordion, Col, Container, Row} from "react-bootstrap";

import {faqList, faqPageSubtitle, faqPageTitle} from "../_pagesContent/FaqPage";

import faqImg from '../assets/faq.svg'

const FaqPage: FC = () => {
  return (
    <>
      <Container className="faq-page">
        <Row>
          <Col xs={12} md={5}>
            <h1 className="page-title faq-page__title">
              {faqPageTitle}
            </h1>
            <div className="page-subtitle">
              {faqPageSubtitle}
            </div>
            <img src={faqImg} alt="FAQ"/>
          </Col>

          <Col xs={12} md={7}>
            <Accordion alwaysOpen>
              {faqList.map((el, idx) => (
                <Accordion.Item eventKey={idx.toString()} key={'faqAccordion' + idx}>
                  <Accordion.Header>{el.question}</Accordion.Header>
                  <Accordion.Body><div dangerouslySetInnerHTML={{ __html: el.answer }} /></Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FaqPage;
