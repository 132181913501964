import React from 'react'

export default function GetStarted({ children = 'Get in Touch' }: { children?: React.ReactNode }) {
	return (
		<a href="https://cal.com/therootapp" target="_blank" rel="noopener noreferrer">
			<div className="btn btn-primary">
				{children}
			</div>
		</a>
	)
}