import React, {FC, ReactNode, useEffect} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import DefaultHeader from "./DefaultHeader";
import DefaultFooter from "./DefaultFooter";

interface IMainLayoutProps {
  children?: ReactNode;
}

const DefaultLayout: FC<IMainLayoutProps> = ({children}) => {
  const isChrome = window.navigator.userAgent.includes('Chrome');

  const location = useLocation();

  useEffect(() => {
    if (isChrome) window.scrollTo({top: 0, behavior: 'smooth'});
    else window.scrollTo({top: 0});
  }, [location.pathname]);

  return (
    <div className="default-layout">
      <DefaultHeader />
      <main className="main">
        {children}
        <Outlet />
      </main>
      <DefaultFooter />
    </div>
  );
};

export default DefaultLayout;
