import React from 'react';
import {RouterProvider} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {router} from "./router";

const App = () => {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
}

export default withTranslation()(App)
