import React, {Fragment} from 'react';
import {Container} from "react-bootstrap";
import {
  areYouTheRootContent,
  areYouTheRootLabel,
  areYouTheRootSubtitle,
  areYouTheRootTitle
} from "../_pagesContent/WhyBecomeTheRootPage";
import HomePageContentRow from "../components/HomePageContentRow";

const WhyBecomeTheRootPage = () => {
  return (
    <>
      <Container className="why-become-the-root-page">
        <Container className="container-middle">
          <div className="page-label">
            {areYouTheRootLabel}
          </div>
          <h1 className="page-title">
            {areYouTheRootTitle}
          </h1>
          <div className="page-subtitle">
            {areYouTheRootSubtitle}
          </div>
        </Container>
      </Container>

      <Container className="why-become-the-root-page" style={{paddingTop: 0, paddingBottom: '6rem'}}>
        {areYouTheRootContent.map((el, idx) => (
          <Fragment key={'homePageContent' + idx}>
            <HomePageContentRow label={el.label}
                                title={el.title}
                                description={el.description}
                                content={el.content}
                                direction={idx % 2 === 1 ? 'rtl' : 'ltr'}
            />
          </Fragment>
        ))}
      </Container>
    </>
  );
};

export default WhyBecomeTheRootPage;
