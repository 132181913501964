import iconLinkedin from "../assets/icons/socials/linkedin.svg";
import iconYoutube from "../assets/icons/socials/youtube.svg";
import iconReddit from "../assets/icons/socials/reddit.svg";
import iconTwitter from "../assets/icons/socials/twitter.svg";


// About Page content

export const aboutTitle = 'TheRootApp Command & Control: Redefining the Future of Role Management';

export const aboutSubtitle = [
  'Welcome to TheRootApp, where the future of seamless management begins. Our Command & Control Hub is more than just a platform—it\'s a revolutionary experience that transforms the way you manage roles and assignments across your organization.',
  'At TheRootApp, innovation drives us. Our hub redefines simplicity and efficiency with a unified interface, integrating advanced capabilities into an intuitive design. Say goodbye to complexity and hello to a world where every command delivers precision and streamlines your operations.',
  'TheRootApp Command & Control Hub isn\'t just about managing systems—it\'s about empowering your team to focus on what truly matters. It\'s a future-ready solution that anticipates your needs, accelerates workflows, and sets a new standard for control.',
  'Join us in shaping the future of IT and project management. TheRootApp Command & Control Hub—where innovation meets effortless mastery, today.'
];

export const aboutSocials = [
  {
    icon: iconLinkedin,
    name: 'Linkedin',
    link: 'https://www.linkedin.com/in/the-root-app'
  },
  {
    icon: iconYoutube,
    name: 'YouTube',
    link: 'https://www.youtube.com/@TheRootAppCC'
  },
  {
    icon: iconReddit,
    name: 'Reddit',
    link: 'https://www.reddit.com/r/theroot_ccapp/'
  },
  {
    icon: iconTwitter,
    name: 'Twitter',
    link: 'https://twitter.com/theroot_app'
  }
];
