type INavItem = {
  name: string,
  path: string
}

const nav: INavItem[] = [
  {
    name: 'Pricing',
    path: '/pricing'
  },
  {
    name: 'Why become TheRoot?',
    path: '/whybecometheroot'
  },
  {
     name: 'FAQ',
      path: '/faq'
  },
  {
    name: 'About',
    path: '/about'
  }
];

export default nav;
