import { Link } from 'react-router-dom';
import './styles.scss';

export const ErrorPage = () => {
  return (
    <div className="ErrorPage">
      <div>
        <h1>404</h1>
        <h2>PAGE NOT FOUND</h2>
        <Link to="/">Home</Link>
      </div>
    </div>
  );
};
