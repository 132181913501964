
const imageUrl = 'https://www.uscreen.tv/wp-content/uploads/2021/02/royalty-free-music.png';


 // FAQ Page content

export const faqPageTitle = 'Frequently Asked Questions (FAQ) about TheRootApp C&C: Elevate Your Business to New Heights';
export const faqPageSubtitle = 'We simplify role and access management for third-party systems by providing a centralized, user-friendly interface. No more navigating multiple systems or relying on IT for simple tasks—our solution saves time, boosts productivity, and eliminates hassle.';

export const faqList = [
  {
    question: 'What is TheRootApp?',
    answer: 'TheRootApp is a revolutionary solution designed to transform your IT landscape and elevate your business to new heights. It empowers your IT team by streamlining operations, igniting innovation, and enhancing security, resulting in unparalleled success.',
    image: imageUrl
  },
  {
    question: 'Is your solution an identity management?',
    answer: 'Nope, our tool is not designed to replace identity management systems. Instead, it complements them by focusing on fast, role-specific access management. For example, you can use Okta for SSO and our solution for streamlined role assignments within systems.',
    image: imageUrl
  },
  {
    question: 'Why should I use your solution if I already have an identity management system?',
    answer: 'Identity management systems often don\'t provide granular role assignment interfaces for third-party tools. TheRootApp eliminates the need to navigate each tool\'s UI, saving time and allowing non-technical users to manage roles effectively.',
    image: imageUrl
  },
  {
    question: 'How does your solution enhance productivity compared to identity management systems?',
    answer: 'While Identity management systems (like Entra ID or Okta) handle authentication, our solution speeds up specific tasks like assigning roles to projects or updating user permissions, reducing what would take hours to just a few clicks.',
    image: imageUrl
  },
  {
    question: 'What if I don\'t use identity management systems like Entra ID or Okta?',
    answer: 'No problem! Our solution works independently to manage roles within integrated third-party tools, so it\'s ideal for small to medium-sized organizations without a dedicated identity management system.',
    image: imageUrl
  },
  {
    question: 'Can your solution help us understand the full scope of our assets?',
    answer: 'Absolutely! Our platform offers a unified view that aggregates data from all integrated third-party systems. This means you can: <ul><li>See a comprehensive overview of user roles and project assignments across all tools in use.</li> <li>Switch between user-centric views (all assets a user has access to) or project/system views (who has access and what roles they have).</li> <li>Gain real-time visibility into the current state of roles and assignments without navigating multiple systems.</li> </ul><br/>This holistic approach empowers companies to understand their complete role management landscape, making oversight, adjustments, and audits easier than ever.',
    image: imageUrl
  },
  {
    question: 'How is your interface different?',
    answer: 'Our interface is as intuitive as a Google or Bing search bar. Simply type a user, project, or system into the search field, and results are instantly displayed in clear, unified views by users or projects. From there, roles and assignments can be managed in just a few clicks—no technical expertise needed.',
    image: imageUrl
  },
  {
    question: 'How does the unified interface simplify role management?',
    answer: 'Discover how our intuitive interface works in just seconds! Watch our video to see how the Google-like search bar and unified user/project views make managing roles across multiple systems easier than ever.<a href="https://www.youtube.com/watch?v=BDORHNXaKQQ" target="_blank" rel="noopener noreferrer">Watch the demo on YouTube</a>.',
    image: imageUrl
  },
  {
    question: ' Can I really assign roles 5x faster with TheRootApp?',
    answer: 'Yes! See the speed in action in our video demonstration. Experience how TheRootApp lets you assign roles across projects and systems in a fraction of the time it takes with traditional tools.<a href="https://www.youtube.com/watch?v=_HPcK6T7JMA" target="_blank" rel="noopener noreferrer">Watch the speed comparison on YouTube</a>.',
    image: imageUrl
  },
  {
    question: 'Why Are You Different?',
    answer: 'Built for non-technical users, enabling HR or Management to handle role assignments independently. Checking all assigned roles for a team member across integrated tools like Jira, Confluence, Bitbucket, Gitlab, GitHub, Figma and others.',
    image: imageUrl
  },
  {
    question: 'How secure is your solution?',
    answer: 'We prioritize security by adhering to the latest best practices for data encryption and API handling. Roles and assignments are updated securely via authorized connections with each system.',
    image: imageUrl
  },
  {
    question: 'Is TheRootApp suitable for my business size?',
    answer: 'Absolutely. TheRootApp\'s transformative power is designed to benefit businesses of all sizes. Whether you\'re a small startup or a large enterprise, TheRoot\'s strategic approach can enhance your IT operations and drive success.',
    image: imageUrl
  },
  {
    question: 'What makes TheRootApp stand out from other solutions?',
    answer: 'TheRootApp stands out by offering a comprehensive solution that addresses the challenges of daily IT operations. It\'s not just about technical management; it\'s about strategic empowerment, innovation, efficiency, and security – all aimed at elevating your business to new heights. Experience the transformational power of TheRootApp – Elevate your IT, Elevate Your Business! Contact us today to embark on a journey of innovation and success.',
    image: imageUrl
  }
];
