import React, {FC, Fragment, useEffect, useState} from 'react';
import {Card, Container} from "react-bootstrap";
import Carousel from "../components/Carousel/Carousel";

import heroImg from "../assets/tmp/search-page.png";
import jiraLogo from "../assets/tmp/jira_logo.png";
import bitbucketLogo from "../assets/tmp/bitbucket_logo.png";
import githubLogo from "../assets/tmp/github_logo.png";
import asanaLogo from "../assets/tmp/asana_logo.png";
import gitlabLogo from "../assets/tmp/gitlab_logo.png";

import {
  homePageAdditionalContent_grid3,
  homePageAddLabel1,
  homePageAddLabel2, homePageAddSubtitle1,
  homePageAddSubtitle2,
  homePageAddTitle1,
  homePageAddTitle2,
  homePageContent, homePageLabel,
  homePageSubtitle,
  homePageTitle
} from "../_pagesContent/HomePage";
import HomePageContentRow from "../components/HomePageContentRow";
import GetStarted from "../components/GetStarted";

const HomePage: FC = () => {
  const [carouselItems, setCarouselItems] = useState(5);

  const carouselIcons = [jiraLogo, bitbucketLogo, githubLogo, asanaLogo, gitlabLogo];

  useEffect(() => {
    window.addEventListener('resize', () => onScreenResizeHandler());
    return () => window.removeEventListener('resize', () => onScreenResizeHandler());
  }, []);

  const onScreenResizeHandler = () => {
    const width = window.innerWidth;
    let items = 7;

    if (width < 576) items = 2;
    else if (width < 768) items = 3;
    else if (width < 991) items = 4;
    else if (width < 1170) items = 5;
    else if (width < 1440) items = 6;
    else items = 7;

    setCarouselItems(items);
  };

  return (
    <>
      <Container className="home-page">
        <Container className="container-middle">
          <div className="page-label">
            {homePageLabel}
          </div>
          <h1 className="page-title">
            {homePageTitle}
          </h1>
          <div className="page-subtitle">
            {homePageSubtitle}
          </div>
          <div className="button-group">
            <GetStarted />
          </div>
        </Container>

        <div className="promo-wrapper">
          <Card className="promo-card">
            <img src={heroImg} alt=""/>
          </Card>
        </div>
      </Container>

      <Container className="home-page" style={{paddingTop: '4.5rem', paddingBottom: 0}}>
        {homePageContent.map((el, idx) => (
          <Fragment key={'homePageContent' + idx}>
            <HomePageContentRow label={el.label}
                                title={el.title}
                                description={el.description}
                                content={el.content}
                                direction={idx % 2 === 1 ? 'rtl' : 'ltr'}
            />
          </Fragment>
        ))}

        <Container className="container-middle" style={{paddingTop: '10rem', paddingBottom: '0'}}>
          <div className="page-label page-label--sm">
            {homePageAddLabel1}
          </div>
          <h2 className="page-title page-title--sm">
            {homePageAddTitle1}
          </h2>
          <div className="page-subtitle">
            {homePageAddSubtitle1}
          </div>
        </Container>

        <div className="home-page__carousel-wrapper">
          <Carousel horizontalCentered={true}
                    slidesToShow={carouselItems}
                    slidesToScroll={1}
                    dots={false}
                    arrows={false}
                    adaptiveHeight={true}
                    speed={1000}
                    autoplaySpeed={2000}
          >
            {carouselIcons.map((icon, idx) => {
              return (
                <div key={'slickItem' + idx}>
                  <div className="carousel__slick-item">
                    <img src={icon} alt="" className="carousel__image"/>
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>

        <Container className="container-middle" style={{paddingTop: '10rem', paddingBottom: '3.5rem'}}>
          <div className="page-label page-label--sm">
            {homePageAddLabel2}
          </div>
          <h2 className="page-title page-title--sm" style={{maxWidth: '800px', margin: '0 auto'}}>
            {homePageAddTitle2}
          </h2>
          <div className="page-subtitle">
            {homePageAddSubtitle2}
          </div>
        </Container>

        <div className="additional-content additional-content__grid-3">
          {homePageAdditionalContent_grid3.map((el, idx) => (
            <div key={'homePageAdditionalContent_grid3' + idx} className="d-flex flex-column">
              <div className="additional-content__title">
                <span className={el.faIcon} aria-hidden="true"/>
                {el.title}
              </div>
              <div className="additional-content__description">{el.description}</div>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default HomePage;
